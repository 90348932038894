import React  from 'react';
import './avatar.scss'
import userAvatar from '../assets/userAvatar.png';
import { getConfig } from '@src/defineConfig';


// 系统头像
export const SystemAvatar = () => {
    return (
        <div className='system-avatar'></div>
    )
}

// 用户头像
export const UserAvatar = () => {
    const userHeadPicture = getUserHeadPicture();
    return (
        <div className='user-avatar'>
            <img src={userHeadPicture}></img>
        </div>
    )
}

function getUserHeadPicture(){
    const config = getConfig();
    if(config?.userHeadPicture) return config.userHeadPicture;
    return userAvatar;
}