import React, { useEffect } from 'react';
import { render } from 'react-dom';
import { store } from './store';
import { observer } from 'mobx-react';
import './style.scss';

export interface IAIAssistantPlugin {
  position?: 'left' | 'right'; // 默认右侧
  x?: number; // 浏览器横向位置
  y?: number; // 浏览器纵向位置
  width?: number; // 小畅助手的宽度（默认52px）
  height?: number; // 小畅助手的高度 (默认52px）
}

// 初始坐标
const initialposition = {
  x: window.innerWidth - 94,
  y: 45,
  width: 52,
  height: 52
};

/**
 * 设置小畅logo的初始位置
 * @param {IAIAssistantPlugin} params
 * @returns {void}
 */
export function setInitialPosition(params: IAIAssistantPlugin) {
  const position = params.position ? params.position : 'right';
  if (position === 'left') {
    initialposition.x = getInitialPosition('horizontal', position, params?.x);
    initialposition.y = getInitialPosition('vertical', position, params?.y);
    return;
  }
  initialposition.x = getInitialPosition('horizontal', position, params?.x);
  initialposition.y = getInitialPosition('vertical', position, params?.y);

  if(params.width) initialposition.width = params.width;
  if(params.height) initialposition.height = params.height;
}

// 获取坐标的初始位置
function getInitialPosition(type: string, position: string, value: number) {
  if(type === 'vertical') {
    if(!value) return 0;
    return value;
  }

  let coordinateValue = 94;
  if(position === 'left') {
     if(value === 0) {
      coordinateValue = 0;
      return coordinateValue;
     }
     if(!value) return coordinateValue;
     coordinateValue = value;
  }

  if (position === 'right') {
    if(!value) coordinateValue = window.innerWidth - initialposition.width;
    if(value) coordinateValue =  window.innerWidth - value - initialposition.width;
  }

  return coordinateValue;
}

export const Navigation = observer((props: IAIAssistantPlugin) => {
  // 鼠标按下时间
  let mouseDownTimer;

  // 鼠标抬起时间
  let mouseUpTimer;

  const handleClick = async () => {
    const timeInterval = mouseUpTimer - mouseDownTimer;
    // 如果时间间隔小于200ms，认为是点击事件
    if (timeInterval <= 200) {
      store.setDrawerState(true);
    }
  };

  useEffect(() => {
    // 监听页面的mouseleave事件，当鼠标移出浏览器页面可用区域 并 松开按键时，停止拖动
    document.addEventListener('mouseleave', (e) => {
      document.onmousemove = document.onmouseup = null;
    });
  }, []);

  const handleMouseDown = (e) => {
    const box = document.getElementById('navigation-logo');
    mouseDownTimer = Date.now();
    // e.pageX, e.pageY 是鼠标在页面上的坐标
    // box.offsetLeft, box.offsetTop 是元素相对于页面左上角的偏移位置
    // disx, disy 便是鼠标相对于元素左上角的偏移位置
    const disx = e.pageX - box.offsetLeft;
    const disy = e.pageY - box.offsetTop;

    document.onmousemove = function (e) {
      // 鼠标移动的时候计算元素的位置
      e.preventDefault();
      let x, y;
      // e.pageX - disx  鼠标在页面上的位置 - 鼠标在元素中的偏移位置  得到的是元素相对于页面左上角的偏移位置
      if (e.pageX - disx > 0) {
        // 元素相对于页面左上角的偏移位置 大于0时
        if (e.pageX - disx > document.documentElement.clientWidth - initialposition.width) {
          // 元素相对于页面左上角的偏移位置 移出到页面以外（右侧）
          x = document.documentElement.clientWidth - (initialposition.width / 2); // 64是元素自身的宽高， 隐藏一半
        } else {
          x = e.pageX - disx;
        }
      } else {
        // 元素移到到页面以外（左侧）
        // 隐藏元素的一半
        x = -(initialposition.width / 2);
      }

      if (e.pageY - disy > 0) {
        if (e.pageY - disy > document.documentElement.clientHeight - initialposition.height) {
          // 元素移动到页面以外（底部）
          y = document.documentElement.clientHeight - initialposition.height;
        } else {
          y = e.pageY - disy;
        }
      } else {
        // 元素移动到页面以外（顶部）
        y = 0;
      }

      box.style.left = x + 'px';
      box.style.top = y + 'px';
    };
  };

  // 释放鼠标按钮，将事件清空，否则始终会跟着鼠标移动
  const handleMouseUp = () => {
    mouseUpTimer = Date.now();
    document.onmousemove = document.onmouseup = null;
  };

  return (
    <div
      onClick={handleClick}
      className="navigation-logo"
      id="navigation-logo"
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      style={{
        left: initialposition.x,
        top: initialposition.y,
        width: initialposition.width,
        height: initialposition.height
      }}
    ></div>
  );
});

export function loadAILogo(params: IAIAssistantPlugin = {}) {
  if(window.document){
    const doc = window.document;
    const aiLogo = doc.getElementById('aiNavigation');
    // 已经加载无需再次加载
    if(aiLogo) return; 
    const node = doc.createElement('div');
    node.setAttribute('id', 'aiNavigation');
    doc.body.appendChild(node);
    const { position, x, y } = params;
    render(
      <Navigation 
        position={position}
        x={x}
        y={y}
      />,
    node);
  }else {
    window.addEventListener('DOMContentLoaded', () => {
      const doc = window.document;
      const aiLogo = doc.getElementById('aiNavigation');
      // 已经加载无需再次加载
      if(aiLogo) return; 
      const node = doc.createElement('div');
      node.setAttribute('id', 'aiNavigation');
      doc.body.appendChild(node);
      const {position, x, y} = params;
      render(
        <Navigation 
          position={position}
          x={x}
          y={y}
        />,
      node);
    });
  }
}
