import { merge } from 'lodash';
import { createClient } from './util/client';

let currentPluginKey: string;

export const setCurrentPlugin = (key: string) => {
    currentPluginKey = key;
    createClient();
}

export const getCurrentPlugin = (): IAIAssistantPlugin => {
    if(pluginsMap.size === 0) return null;
    if(!currentPluginKey){
        currentPluginKey = pluginsMap.values().next().value.topicTheme;
        createClient();
    }
    return pluginsMap.get(currentPluginKey);
};

/**
 * 插件列表集合
 */
export const pluginsMap = new Map();


interface IAIAssistantPlugin {
    appKey: string; // dify平台生成的API秘钥
    topicTheme: string; // 语料主题
    topicName: string; // 语料名称
    beforeRequest?: (content: any) => { isContinueRequest: boolean; content?: string }; // 请求之前处理逻辑
    beforeSystemAnswer?: (content: any) => string; // 系统消息显示前的处理逻辑
    mode?: 'chat' | 'completion'; // 应用模式 默认对话模式（chat）
    customInfo?: ICustomInfo; // 用户自定义信息
    customPromptMsgList?: Array<{ content: string }>; // 首页第二条提示消息列表
    customFeedbackMsg?: Array<{ key: number; content: string }>; // 自定义反馈消息tag列表
    customMarkdownFunctions?: {[key: string]: Function}; //自定义markdown按钮的方法
    customButton?: ICustomButton; // 自定义面板底部按钮
}

interface ICustomInfo {
    jumpPage?: Function; // 跳转菜单的方法
    [key: string]: any;
}

interface ICustomButton {
    buttonName: string;
    buttonFun?: Function;
}

export function definePlugin(plugin: IAIAssistantPlugin){
    // 当前已存在的插件，进行配置合并
    if(pluginsMap.has(plugin.topicTheme)) {
       const mergedPlugin = merge(pluginsMap.get(plugin.topicTheme), plugin);
       pluginsMap.set(plugin.topicTheme, mergedPlugin);
       return;
    }
    pluginsMap.set(plugin.topicTheme, plugin);
}

