/**
 * 错误消息
 */
import React from "react";
import { SystemAvatar } from '../avatar';
import { SystemMessage } from './systemMessage';


export const ErrorMessage = (props) => {
    return(
        <div>
            <li className="aichat-msg-assistant">
                <SystemAvatar />
                <SystemMessage
                    content={'系统出现错误啦，请重试'}
                    isFullScreen={props.isFullScreen}
                />
            </li>
        </div>
    )
}