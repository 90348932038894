/**
 * 通用的用户级消息，role为user发的消息
 */
import React from "react";
import { getConfig } from "@src/defineConfig";
import './message.scss';

export const UserMessage = (props) => {
    const config = getConfig();
    const { content, isFullScreen } = props;
    return (
        <div
            className="aichat-content-user"
            style={{ 
                background: config?.theme?.ThemePrimaryColor ?? '#5161FE',
                maxWidth: isFullScreen ? window.innerWidth - 100 : 300,
                minWidth: isFullScreen ? 0 : 48,
            }}
        >
            <span>{content}</span>
            <div style={{borderLeftColor: config?.theme?.ThemePrimaryColor ?? '#5161FE'}}>
            </div>
        </div>
    );
};