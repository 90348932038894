import React, { useState, useEffect, useRef } from 'react';
import { render } from 'react-dom';
import { loadAILogo } from './navigation';
import { Button, Input, message, Drawer, Switch, ConfigProvider } from 'antd';
import { ClearOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';
import { useAsyncEffect } from 'ahooks';
import { store } from './store';
import {
  CloseIcon,
  FullScreen,
  ExitFullScreen,
  SetupIcon,
  CustomService,
  ReturnIcon,
  WarningIcon,
} from '../components/icon';
import {
  SystemAvatar,
  UserAvatar,
  SystemMessage,
  UserMessage,
  OpenMessage,
  LoadMessage,
  ErrorMessage,
} from '../components';
import { getCurrentPlugin } from '@src/definePlugin';
import { getConfig, getEnv } from '@src/defineConfig';
import { appInstance } from '@src/util/client';
import './style.scss';

const { TextArea } = Input;

export interface IGPTMessage {
  role: 'system' | 'user' | 'assistant';
  content: string;
}

/**
 * AI助手扩展插件
 *
 * @export
 * @interface IAIAssistantPlugin
 */
export interface IAIAssistantPlugin {
  /**
   * 获取当前会话的提示词
   *
   * @type {string[]}
   * @memberof IAIAssistantPlugin
   */
  defaultPrompts: string[];

  /**
   * 解析显示时消息显示格式
   *
   * @param {IGPTMessage} message
   * @return {*}  {string}
   * @memberof IAIAssistantPlugin
   */
  parseMessage(message: IGPTMessage): string;

  /**
   * 新会话启动时机
   *
   * @param {any[]} session
   * @return {*}  {Promise<void>}
   * @memberof IAIAssistantPlugin
   */
  newSession(session: any[]): Promise<void>;

  /**
   * 当前会话请求前处理时机
   *
   * @param {IGPTMessage[]} session
   * @return {*}  {Promise<void>}
   * @memberof IAIAssistantPlugin
   */
  fetchMessage(session: IGPTMessage[], cts: number): Promise<void>;

  /**
   * 当前会话消息处理时机
   *
   * @param {IGPTMessage} message
   * @param {IGPTMessage[]} session
   * @return {*}  {Promise<void>}
   * @memberof IAIAssistantPlugin
   */
  receiveMessage(
    message: IGPTMessage,
    session: IGPTMessage[],
    cts: number
  ): Promise<void>;

  /**
   * 调试模式
   *
   * @param {boolean} mode
   * @memberof IAIAssistantPlugin
   */
  setDebug(mode: boolean);
}

export const hooks: Array<() => IAIAssistantPlugin> = [];

let ts;
let lastMessageId = '';

export const AiAssistant = observer(() => {
  const plugins: IAIAssistantPlugin[] = [];
  for (const usePlugin of hooks) {
    plugins.push(usePlugin());
  }
  const [defaultPrompts, setDefaultPrompts] = useState(
    plugins.reduce(
      (prompts, plugin) => prompts.concat(plugin.defaultPrompts),
      []
    )
  );

  const [userQuestion, setQuestion] = useState('');
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(store.drawerState);
  const [status, setStatus] = useState<any>('');
  const [session, setSession] = useState<IGPTMessage[]>([]);
  const [messageId, setMessageId] = useState(lastMessageId);
  const [drawerWidth, setDrawerWidth] = useState(378);
  const [isFullScreen, setFullScreen] = useState(false);
  // 对话id
  const [conversationId, setConversationId] = useState('');
  const [showSetupPage, setShowSetupPage] = useState(false);

  // 输入框的内容
  const [inputValue, setInputValue] = useState('');
  const inputEl = useRef(null);

  const drawerBodyEl = document.getElementsByClassName('ant-drawer-body')[0];
  const sendButton = document.getElementById('sendButton');

  // 设置页面开关状态
  const [switchStatus, setSwitchStatus] = useState(false);

  // 焦点设置
  const [isFocused, setIsFocused] = useState(false);

  // 当前的plugin
  const currentPlugin = getCurrentPlugin();
  const config = getConfig();

  // 点击其他区域收起抽屉
  useEffect(() => {
    document.addEventListener('click', function (event) {
      const targetElement = event.target; // 获取当前点击的元素
      const drawerElement = document.querySelector(
        '.chatgpt-drawer'
      ); // 抽屉
      const navigationElement = document.querySelector('.navigation-logo'); // aiLogo
      // @ts-ignore
      if (targetElement !== drawerElement && !drawerElement?.contains(targetElement) && targetElement !== navigationElement) {
        if (store.drawerState) {
          store.setDrawerState(false);
          setFullScreen(false);
          setShowSetupPage(false);
        }
      }
    });
  }, null);

  useEffect(() => {
    setOpen(store.drawerState);
  }, [store.drawerState]);

  useEffect(() => {}, [store.sessionType]);

  // 监听loading变化
  useEffect(() => {
    drawerScroll();
  }, [loading]);

  // 初始化 只执行一次
  useEffect(() => {
    newSession(true);
  }, []);

  const getAILogoStatus = async () => {
    if (config?.getAILogoStatus) {
      const visible = await config.getAILogoStatus();
      return visible === false ? false : true;
    }
    return true;
  };

  useAsyncEffect(async () => {
    const initialAILogoStatus = await getAILogoStatus();
    setSwitchStatus(initialAILogoStatus);
  }, []);

  useAsyncEffect(async () => {
    if (loading || session.filter((msg) => msg.role === 'user').length <= 0) {
      return;
    }
    const cts = new Date().getTime();
    ts = cts;
    setLoading(true);
    setOpen(true);

    try {
      for (const plugin of plugins) {
        await plugin.fetchMessage(session, cts);
      }

      // 询问chatgpt
      const message = await fetchCompletions(session);
      if (ts !== cts) return;
      const st = session.concat(message);
      for (const plugin of plugins) {
        await plugin.receiveMessage(message, st, cts);
      }
      setSession(st);
    } catch (err) {
      message.error(err.message);
      setStatus('error');
    }
    setLoading(false);
  }, [session.filter((msg) => msg.role === 'user').length]);

  async function fetchCompletions(
    session: IGPTMessage[]
  ): Promise<IGPTMessage> {
    // 询问chatgpt之前处理
    if(currentPlugin?.beforeRequest){
      const result = await currentPlugin.beforeRequest(session[session.length - 1].content);
      // 用户取消了请求，则不继续请求
      if(!result.isContinueRequest){
        return {
          role: 'assistant',
          content: result?.content,
        }
      }
    }
    const env = getEnv();
    // const result = await new Promise((resolve, reject) => {
    //     setTimeout(() => {
    //         resolve('模拟后端的回答');
    //     }, 1000);
    // });
    // return {
    //     role: "assistant",
    //     content: result as string
    // };
    const mode = currentPlugin?.mode ?? 'chat';
    const result = await appInstance.client.post(
      `https://${env}ai-api.chanapp.chanjet.com/v1/${mode}-messages`,
      {
        inputs: {},
        query: session[session.length - 1].content,
        response_mode: 'blocking',
        conversation_id: conversationId,
        user: config?.userId ? config.userId : '',
      },
    );
    const res = await handleAnswer(result.data.answer);
    setMessageId(result.data.id);
    // 如果是对话式, 更新每一次的conversationId
    if (mode === 'chat') setConversationId(result.data.conversation_id);
    return {
      role: 'assistant',
      content: res.content,
    };
  }

  async function newSession(noMessage: boolean = false) {
    const session = [];
    for (const plugin of plugins) {
      await plugin.newSession(session);
    }
    setSession(session);
    setStatus('');
    setConversationId('');
    setDefaultPrompts(
      plugins.reduce(
        (prompts, plugin) => prompts.concat(plugin.defaultPrompts),
        []
      )
    );
    if (!noMessage) message.info('新会话已重置');
    if(config?.clearSessionCallback && !noMessage){
      await config.clearSessionCallback();
    }
  }

  function handlePromptChange(value) {
    setQuestion(value);
    setInputValue(value);
  }

  function handleEnter(e) {
    if (!inputValue.trim() || loading) return null;
    // 当用户使用中文输入法进行拼音组合输入时，例如在输入框中逐步输入拼音形成一个词语时，isComposing 的值为 true。一旦用户确定了最终的词语并按下确定键（例如回车键），isComposing 的值将变为 false。
    if (e.nativeEvent.isComposing) return null;
    e.preventDefault();
    inputEl.current.focus();
    setStatus('');
    setQuestion('');
    setSession(
      session.concat({
        role: 'user',
        content: userQuestion,
      })
    );
    setInputValue('');
  }

  // 用户点击提示发送的消息
  function userSendMessage(content) {
    if (loading) return null;
    setStatus('');
    setQuestion('');
    setSession(
      session.concat({
        role: 'user',
        content: content,
      })
    );
  }

  // 自定义用户发消息事件，供外部使用
  window.addEventListener('customUserSendMessage', function (event) {
    userSendMessage((event as CustomEvent).detail.content);
  });

  // 系统发消息
  function systemSendMessage(content) {
    setStatus('');
    setSession(
      session.concat({
        role: 'assistant',
        content: content,
      })
    );
    setTimeout(() => {
      drawerScroll();
    }, 0);
  }

  function stopRequest(event) {
    ts = null;
    setSession(
      session.concat({
        role: 'assistant',
        content: `已为您停止回答`,
      })
    );
    setLoading(false);
    event.stopPropagation();
  }

  // 控制聊天面板会话永远在底部
  function drawerScroll() {
    if (drawerBodyEl) {
      drawerBodyEl.scrollTop = drawerBodyEl.scrollHeight;
    }
  }

  function handleClose() {
    setOpen(false);
    setShowSetupPage(false);
    setFullScreen(false);
    setDrawerWidth(378);
    store.setDrawerState(false);
  }

  // 页脚部分
  function renderFooter() {
    return (
      <div className="footer-wrapper" id="footer-wrapper">
        <TextArea
          placeholder="请输入信息"
          onPressEnter={(e) => handleEnter(e)}
          onChange={(e) => handlePromptChange(e.target.value)}
          style={{ height: 80.5, resize: 'none' }}
          className="chatgpt-input"
          bordered
          value={inputValue}
          ref={inputEl}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        />
        <div className="footer-bottom">
          <div className="footer-bottom-left">
            <div className="bottom-left-clear" onClick={() => newSession()}>
              <ClearOutlined title="开始新会话" />
              <span className="bottom-left-clear-tip">清空</span>
            </div>
            {config?.isNeedCustomService && (
              <div
                className="bottom-left-customservice"
                onClick={() => {
                  config?.customServiceFunction?.();
                  store.setDrawerState(false);
                }}
              >
                <CustomService />
                <span className="bottom-left-customservice-tip">转人工</span>
              </div>
            )}
          </div>
          {currentPlugin?.customButton && (
            <Button onClick={() => currentPlugin.customButton?.buttonFun()}>
              {currentPlugin.customButton.buttonName}
            </Button>
          )}
          <Button onClick={handleEnter} id={'sendButton'} type="primary">
            发送
          </Button>
        </div>
      </div>
    );
  }

  // 渲染右上角区域
  function renderExtraComponent() {
    const showFullScreen = config?.showFullScreen === false ? false : true;
    return (
      <div className="header-right">
        {config?.openSetup && (
          <div
            className="header-right-setup"
            onClick={async (event) => {
              const aiLogoVisible = await getAILogoStatus();
              setSwitchStatus(aiLogoVisible);
              setShowSetupPage(true);
              event.stopPropagation();
            }}
          >
            <SetupIcon />
          </div>
        )}
        {showFullScreen && (
          <div
            onClick={(event) => handelFullscreen(event)}
            className="header-right-screen"
          >
            {isFullScreen ? <ExitFullScreen /> : <FullScreen />}
          </div>
        )}
        {!config?.notNeedCloseButton && (
          <div onClick={handleClose} className="header-right-close">
            <CloseIcon />
          </div>
        )}
      </div>
    );
  }

  // 控制全屏
  function handelFullscreen(event) {
    const width = isFullScreen ? 378 : window.innerWidth;
    setDrawerWidth(width);
    setFullScreen(!isFullScreen);
    event.stopPropagation();
  }

  // 渲染设置页面
  function renderSetupPage() {
    return (
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: config?.theme?.ThemePrimaryColor ?? '#5161FE',
          },
        }}
      >
        <Drawer
          title={renderSetupTitle()}
          placement="right"
          open={open}
          mask={false}
          width={drawerWidth}
          className={
            isFullScreen ? 'chatgpt-drawer-fullscreen' : 'chatgpt-drawer'
          }
          closable={false}
          style={{ background: config?.theme?.ThemePrimaryColor ?? '#6C87FE' }}
          bodyStyle={{
            background: config?.theme?.ThemeSecondaryColor ?? '#EFF2FB',
          }}
        >
          <div className="setup-body">
            <div className="setup-body-list">
              <span className="setup-list-content">小畅AI助手 快捷窗口</span>
              <Switch
                checked={switchStatus}
                onChange={(value) => handleLogoVisible(value)}
              />
            </div>
            <div className="setup-body-tip">
              <WarningIcon />
              <span className="setup-tip-content">
                关闭入口后可以在页面右上角【我的】进入及重新开启快捷入口
              </span>
            </div>
          </div>
        </Drawer>
      </ConfigProvider>
    );
  }

  // 设置页面title
  function renderSetupTitle() {
    return (
      <div className="setup-header">
        <div
          className="setup-header-left"
          onClick={(event) => {
            setShowSetupPage(false);
            event.stopPropagation();
          }}
        >
          <ReturnIcon />
        </div>
        <div className="setup-header-middle">设置</div>
        {!config?.notNeedCloseButton && (
          <div onClick={handleClose} className="setup-header-right">
            <CloseIcon />
          </div>
        )}
      </div>
    );
  }

  // 控制小畅助手的显示隐藏
  async function handleLogoVisible(visible: boolean) {
    if (config?.setAILogoStatus) {
      await config.setAILogoStatus(visible);
    }
    if (visible) {
      loadAILogo();
      setSwitchStatus(true);
      return;
    }
    setSwitchStatus(false);
    // 移除logo
    const aiLogo = document.getElementById('aiNavigation');
    if (!visible && aiLogo) {
      aiLogo.parentNode.removeChild(aiLogo);
    }
  }

  if (showSetupPage) {
    return renderSetupPage();
  }
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: config?.theme?.ThemePrimaryColor ?? '#5161FE',
        },
      }}
    >
      <Drawer
        title="小畅AI助手"
        placement="right"
        open={open}
        mask={false}
        width={drawerWidth}
        className={
          isFullScreen ? 'chatgpt-drawer-fullscreen' : 'chatgpt-drawer'
        }
        footer={renderFooter()}
        extra={renderExtraComponent()}
        closable={false}
        style={{ background: config?.theme?.ThemePrimaryColor ?? '#6C87FE' }}
        bodyStyle={{
          background: config?.theme?.ThemeSecondaryColor ?? '#EFF2FB',
        }}
        footerStyle={{
          background: config?.theme?.ThemeSecondaryColor ?? '#EFF2FB',
        }}
      >
        <ul>
          {
            <OpenMessage
              sendMessage={(content) => {
                userSendMessage(content);
              }}
              isFullScreen={isFullScreen}
            />
          }
          {session
            .filter((msg) => msg.role !== 'system')
            .map((msg, index) => {
              let text = msg.content;
              return (
                <li className={'aichat-msg-' + msg.role} key={index}>
                  {msg.role === 'user' && <UserAvatar />}
                  {msg.role === 'system' && <SystemAvatar />}
                  {msg.role === 'assistant' && <SystemAvatar />}
                  {msg.role === 'user' && (
                    <UserMessage content={text} isFullScreen={isFullScreen} />
                  )}
                  {(msg.role === 'assistant' || msg.role === 'system') && (
                    <SystemMessage
                      content={text}
                      messageId={messageId || lastMessageId}
                      sendMessage={(content) => systemSendMessage(content)}
                      isFullScreen={isFullScreen}
                    />
                  )}
                </li>
              );
            })}
          {status === 'error' && <ErrorMessage isFullScreen={isFullScreen} />}
          {loading && <LoadMessage onClick={(event) => stopRequest(event)} />}
        </ul>
      </Drawer>
    </ConfigProvider>
  );
});

// 处理后端的回答
async function handleAnswer(backEndResult) {
  const result = backEndResult;
  const currentPlugin = getCurrentPlugin();
  if (currentPlugin?.beforeSystemAnswer) {
    try {
      const handeledAnswer = await currentPlugin.beforeSystemAnswer(result);
      return {
        role: 'assistant',
        content: handeledAnswer,
      };
    } catch (err) {
      console.error(err);
      return {
        role: 'assistant',
        content: '当前无法回答，请重试！',
      };
    }
  }

  if (
    Array.isArray(result) &&
    result.length > 0 &&
    currentPlugin.topicTheme === 'menu'
  ) {
    const formatResult = result.map((item) => {
      return {
        name: item.name,
        routeId: item.routeId,
        menu_id: item.menu_id,
      };
    });
    const stringMenuResult = JSON.stringify(formatResult);
    return {
      role: 'assistant',
      content: `menuList${stringMenuResult}`,
    };
  }

  if (typeof result === 'string') {
    return {
      role: 'assistant',
      content: result,
    };
  }

  return {
    role: 'assistant',
    content: '对不起，暂时无法回答您的问题',
  };
}

export function loadAIDrawer() {
  if (window.document) {
    const doc = window.document;
    const aichatbox = doc.getElementById('aichatbox');
    // 已经加载无需再次加载
    if (aichatbox) return;
    const node = doc.createElement('div');
    node.setAttribute('id', 'aichatbox');
    doc.body.appendChild(node);
    render(<AiAssistant></AiAssistant>, node);
  } else {
    window.addEventListener('DOMContentLoaded', () => {
      const doc = window.document;
      const aichatbox = doc.getElementById('aichatbox');
      // 已经加载无需再次加载
      if (aichatbox) return;
      const node = doc.createElement('div');
      node.setAttribute('id', 'aichatbox');
      doc.body.appendChild(node);
      render(<AiAssistant></AiAssistant>, node);
    });
  }
}

export function userSendMessage(content) {
  const event = new CustomEvent('customUserSendMessage', {
    detail: { content },
  });
  window.dispatchEvent(event);
}