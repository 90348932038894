import { merge } from 'lodash';

interface IBasicConfig {
    isNeedLogo?: boolean; // 是否需要小畅图标
    env?: 'test' | 'inte' | 'prod'; // 环境变量
    theme?: {
        ThemePrimaryColor?: string; // 主色
        ThemeSecondaryColor?: string; // 辅色
    };
    showFullScreen?: boolean; // 显示全屏按钮 默认需要
    userHeadPicture?: string; // url 自定义用户头像
    userId?: string | number; // 用户id,给每条消息传入的id，保证唯一
    firstPrompt?: string; // 开屏第一条提示语
    isNeedSecondPrompt?: boolean; // 是否需要第二条提示语（tab页签提示语）
    isNeedCustomService?: boolean; // 是否展示人工服务按钮
    customServiceFunction?: Function; // 唤起人工客服的方法
    notNeedFeedBack?: boolean; // 不需要反馈机制
    notNeedStopButton?: boolean; // 不需要停止按钮
    notNeedCloseButton?: boolean; // 不需要关闭按钮
    clearSessionCallback?: Function; // 清空会话回调
    // CC产品内部专用
    openSetup?: boolean; // 开启设置页面
    setAILogoStatus?: Function; // 设置logo状态
    getAILogoStatus?: Function; // 获取logo状态
}

let config: IBasicConfig = null;
export const getConfig = (): IBasicConfig => config;

export function defineConfig(option: IBasicConfig){
    if(config){
        config = merge(config, option);
        return;
    }
    config = option;
}

/**
 * 获取环境变量
 */
export function getEnv(){
    const envMap = {
        test: 'test-',
        inte: 'inte-',
        prod: ''
    };
    if(config?.env){
        return envMap[config.env];
    }
    return envMap.test;
}