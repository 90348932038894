/**
 * 系统初始消息
 */
import React, { useState } from "react";
import { observer } from "mobx-react";
import { Tabs } from 'antd';
import type { TabsProps } from 'antd';
import { SystemAvatar } from '../avatar';
import { SystemMessage } from './systemMessage';
import { getConfig } from "@src/defineConfig";
import { getCurrentPlugin, pluginsMap, setCurrentPlugin } from "@src/definePlugin";
import { store } from "@src/homePage/store";
import './message.scss';


const TabHeader = (props) => {
    const config = getConfig();
    return (
        <div className='message-header'>
            <div className='message-header-left'></div>
            <div className='message-header-center' style={{color: config?.theme?.ThemePrimaryColor ?? '#696DFF'}}>{props.content}</div>
            <div className='message-header-right'></div>
        </div>
    )
}

export const OpenMessage = observer((props) => {
    const config = getConfig();
    const currentPlugin = getCurrentPlugin();
    const [type, setType] = useState(currentPlugin?.topicTheme);
    // 是否需要第二条提示消息
    const needSecondPrompt = config?.isNeedSecondPrompt ?? false;
    // 第一条提示消息内容
    const defaultPrompt = 'HI~ 我是小畅，目前我有以下超能力，未来能力会更强大，敬请期待哦~';
    const firstPrompt = config?.firstPrompt ?? defaultPrompt;

    const topics = getTopicsList();
    const sendMessage = props.sendMessage;

    const Tab = (props) => {
        const messageList: Array<{content: string}> = props.messageList
        return (
            <div>
                <TabHeader content='你可以这么问'/>
                <div className="message-body">
                    {
                        messageList.map((item, index) => {
                            return(
                                <div 
                                    key={index}
                                    className='message-list'
                                    onClick={(event) => {
                                        sendMessage(item.content);
                                        event.stopPropagation();
                                    }}
                                    style={{background: config?.theme?.ThemeSecondaryColor ?? '#E5EFFE'}}
                                >
                                    {item.content}
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        )
    }

    const onChange = (key: string) => {
        setType(key);
        setCurrentPlugin(key);
        store.setSessionType(key);
    };

    const items: TabsProps['items'] = topics.map((item) => {
        return {
            key: item.topic,
            label: item.tabName,
            children: <Tab messageList={item.promptMsgList}/>
        }
    });

    return (
        <div>
            <li className="aichat-msg-assistant">
                <SystemAvatar />
                <SystemMessage
                    content={firstPrompt}
                    notNeedFeedBack
                    isFullScreen={props.isFullScreen}
                />
            </li>
            {
                needSecondPrompt && topics.length > 0 && (
                    <li className="aichat-msg-assistant">
                        <SystemAvatar />
                        <SystemMessage 
                            content={
                                <Tabs 
                                    defaultActiveKey={type}
                                    items={items} 
                                    onChange={onChange} 
                                    className='aichat-message-tab'
                                    activeKey={type}
                                />
                            }
                            notNeedFeedBack 
                            isFullScreen={props.isFullScreen}
                        />
                    </li>
                )
            }
        </div>
    );
});

/**
 * 获取语料列表（第二条消息Tab页签的提示语）
 */
function getTopicsList() {
    const topics = [];
    for(const value of pluginsMap.values()) {
        topics.push({
            topic: value.topicTheme,
            tabName: value.topicName,
            promptMsgList: value.customPromptMsgList
        });
    }
    return topics;
}