/**
 * 自定义按钮组件，处理markdown里的CustomButton组件
 */
import React from "react";
import { Button } from "antd";
import { getCurrentPlugin } from "@src/definePlugin";

export const MarkdownButton = (props) => {
    const currentPlugin = getCurrentPlugin();
    const { funName, funParam, btnName, ...otherProps } = props;
    return (
        <Button
            {...otherProps}
            onClick={ async () => {
                if(currentPlugin?.customMarkdownFunctions){
                    const excuteFun = currentPlugin.customMarkdownFunctions[funName];
                    if(excuteFun) await excuteFun(funParam);
                }
            }}
        >
            {btnName}
        </Button>
    )
}

export const MarkdownLink = (props) => {
    const currentPlugin = getCurrentPlugin();
    const { funName, funParam, linkName, ...otherProps } = props;
    return (
        <Button 
            {...otherProps}
            type="link"
            onClick={ async () => {
                if(currentPlugin?.customMarkdownFunctions){
                    const excuteFun = currentPlugin.customMarkdownFunctions[funName];
                    if(excuteFun) await excuteFun(funParam);
                }
            }}
        >
            {linkName}
        </Button>
    )
}