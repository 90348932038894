/**
 * 数据状态管理文件
 */
import { makeObservable, observable, action } from 'mobx';
import { pluginsMap } from '../definePlugin';

export class Store {
  drawerState: boolean = false;
  isFirstOpen: boolean = true;
  sessionType: string = '';
  constructor() {
    makeObservable(this, {
      drawerState: observable,
      isFirstOpen: observable,
      sessionType: observable,
      setDrawerState: action,
      setSessionType: action,
    });
    this.sessionType = getSessionType();
  }

  setDrawerState = (value: boolean) => {
    this.drawerState = value;
  }

  setSessionType = (value: string) => {
    this.sessionType = value;
  }
}

export const store = new Store();

function getSessionType(){
  if(pluginsMap.size === 0) return '';
  return Array.from(pluginsMap.values())[0];
}