import { loadAILogo, setInitialPosition } from './homePage/navigation';
import { loadAIDrawer, userSendMessage } from './homePage/floatLayer';
import { createClient } from './util/client';
import { definePlugin } from './definePlugin';
import { defineConfig, getConfig } from './defineConfig';
import { store } from './homePage/store';

export function loadAIAssistant() {
  const config = getConfig();
  createClient();
  // 不需要logo直接渲染
  if(config?.isNeedLogo === false) {
    store.setDrawerState(true);
  } else {
    loadAILogo();
  }
  loadAIDrawer();
}

export function handleAIAssistantVisible(visible: boolean){
  store.setDrawerState(visible);
}

// 关闭（隐藏）小畅助手的logo
export function hideAIAssistant(){
  const aiLogo = document.getElementById('aiNavigation');
  if(aiLogo){
    aiLogo.parentNode.removeChild(aiLogo);
  }
}

export { setInitialPosition, definePlugin, defineConfig, userSendMessage };
