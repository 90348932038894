/**
 * 加载中消息
 */
import React from "react";
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { SystemAvatar } from '../avatar';
import { getConfig } from "@src/defineConfig";
import { StopIcon } from '../icon';
import './message.scss';

const antIcon = <LoadingOutlined style={{ fontSize: 16 }} spin />;


export const LoadMessage = (props) => {
    const config = getConfig();
    return(
        <div>
            <li className="aichat-msg-assistant">
                <SystemAvatar />
                <div className='aichat-msg-loading'> 
                    <Spin indicator={antIcon}/>
                </div>
            </li>
            {
                !config?.notNeedStopButton && (
                    <div className='aichat-msg-stop' onClick={props.onClick}>
                        <StopIcon/>
                        <span className='aichat-msg-stop-tip'>停止</span>
                    </div>
                )
            }
        </div>
    )
}