import axios from 'axios';
import { getCurrentPlugin } from '@src/definePlugin';
let appKey = 'Bearer app-3jfWMsNXPZZoS4LvxFbq7ZNf';

export const appInstance = { client: null };

export function createClient() {
  const curentPlugin = getCurrentPlugin();

  if (curentPlugin?.appKey) {
    appKey = 'Bearer ' + curentPlugin.appKey;
  }

  appInstance.client = axios.create({
    headers: {
      Authorization: appKey,
      'Content-Type': 'application/json',
    },
    timeout: 180000
  });
}
