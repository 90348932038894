/**
 * 通用的系统级消息，role为system和assistant发的消息
 */
import React, { useState, useEffect } from "react";
import { Input, Button } from 'antd';
import MarkdownView from 'react-showdown';
import showdownHighlight from 'showdown-highlight';
import 'highlight.js/styles/github.css'; // 根据需要导入适当的样式文件
import { customComponentMap } from "@src/customComponents";
import { appInstance } from "@src/util/client";
import { FeedbackOk, FeedbackError, FeedbackSuccess } from '../icon';
import { MenuListMessage } from "./menuListMessage";
import { getCurrentPlugin } from "@src/definePlugin";
import { getEnv, getConfig } from "@src/defineConfig";
import './message.scss';

const { TextArea } = Input;

interface ISystemMessage {
    content: string | React.ReactNode ,
    notNeedFeedBack?: boolean;
    messageId?: string; // 后端返回的id
    sendMessage?: Function; // 主动发消息
    isFullScreen?: boolean; // 是否全屏
    [key: string]: any;
}

export const SystemMessage = (props: ISystemMessage) => {
    const config = getConfig();
    // 如果配置中配置了不需要反馈机制，所有消息都没有点赞和反馈
    if(config?.notNeedFeedBack){
        return (
            <div className="aichat-system-wrapper">
                { handleMsgContent(props) }
            </div>   
        )
    }
    // 如果配置中没配，需不要反馈机制根据消息类型控制
    return (
        <div className="aichat-system-wrapper">
            { handleMsgContent(props) }
            {
                !props.notNeedFeedBack && 
                <FeedbackMessage
                    messageId={props.messageId}
                    notFeedbackDropDown={isNeedFeedbackDropDown(props.content)}
                    isFullScreen={props.isFullScreen}
                />
            }
        </div>   
    )
}

function handleMsgContent(props: ISystemMessage) {
    const {isFullScreen} = props;
    if(typeof props.content === 'string') {
        let { content } = props;
        // 菜单列表单独处理
        if(content.startsWith('menuList')){
            const MenuList = props.content.split('menuList')[1];
            const formatMenuList = JSON.parse(MenuList);
            return (
                <div 
                    className="aichat-content-system"
                    style={{ maxWidth: isFullScreen ? window.innerWidth - 100 : 300 }}
                >
                    <MenuListMessage 
                        data={formatMenuList} 
                        messageId={props.messageId}
                        sendMessage={props.sendMessage}
                    />
                </div>
            )
        }
        if(content.startsWith('openMenu')){
            content = content.split('openMenu')[1];
        }
        return (
            <div
                className="aichat-content-system"
                style={{ maxWidth: isFullScreen ? window.innerWidth - 100 : 300 }}
            >
                <div 
                    className="content-markdown"
                    style={{overflowX: 'auto'}}
                >
                <MarkdownView 
                    markdown={content} 
                    components={customComponentMap} 
                    options={{ 
                        tables: true, 
                        emoji: true ,
                        extensions: [showdownHighlight],
                    }}
                    style={{ lineHeight: '25px' }}
                />
                </div>
            </div>
        )
    }
    return (
        <div
            className="aichat-content-system"
            style={{ maxWidth: isFullScreen ? window.innerWidth - 100 : 300 }}
        >
            {props.content}
        </div>
    )
}

/**
 * 是否需要反馈输入框
 */
function isNeedFeedbackDropDown(content){
    if(typeof content === 'string' && content.indexOf('openMenu') > -1){
        return true;
    }
    return false;
}

const FeedbackMessage = (props) => {
    const [ feedbackOkResult, setFeedbackOkResult ] = useState(false);
    const [ feedbackErrorResult, setFeedbackErrorResult ] = useState(false);
    const [ feedbackDropDown, setFeedbackDropDown ] = useState(false);
    const [ selectTag, setSelectTag ] = useState(-1);
    const [ inputValue, setInputValue ] = useState('');
    const [ buttonState, setButtonState ] = useState(true);

    const drawerBodyEl = document.getElementsByClassName('ant-drawer-body')[0];
    function drawerScroll(){
        if(drawerBodyEl){
            drawerBodyEl.scrollTop = drawerBodyEl.scrollHeight;
        }
    }

    const { messageId, notFeedbackDropDown, isFullScreen } = props;
    const config = getConfig();

    let userId: string | number = '';
    if(config?.userId){
        userId = config.userId;
    }

    const tagList = annotationsContent();
    const env = getEnv();

    useEffect(() => {
        if(selectTag === -1 && !inputValue){
            setButtonState(true);
        }else{
            setButtonState(false)
        }
    }, [selectTag, inputValue]);

    useEffect(() => {
        drawerScroll();
    }, [feedbackDropDown]);

    async function onFeedbackOkClick(e) {
        e.stopPropagation();
        setFeedbackDropDown(false);
        // 反馈
        const result = await appInstance.client.post(`https://${env}ai-api.chanapp.chanjet.com/v1/messages/${messageId}/feedbacks`, {
            rating: 'like',
            user: userId
        });
        if(result.data.result === 'success') setFeedbackOkResult(true);
    }
    
    async function onFeedbackErrorClick(e) {
        e.stopPropagation();
        if(notFeedbackDropDown){
            setFeedbackDropDown(false);
            // 反馈
            const result = await appInstance.client.post(`https://${env}ai-api.chanapp.chanjet.com/v1/messages/${messageId}/feedbacks`, {
                rating: 'dislike',
                user: userId
            });
            if(result.data.result === 'success') setFeedbackErrorResult(true);
            return;
        }
        setFeedbackDropDown(!feedbackDropDown);
    }
    
    return (
        <div style={{
            marginTop: '8px',
            maxWidth: isFullScreen ? window.innerWidth - 100 : 300 
        }}>
            <div className='aichat-content-feedback'>
                <div 
                    className={(feedbackOkResult || feedbackErrorResult) ? 'aichat-content-feedback-true-disabled' : 'aichat-content-feedback-true'}
                    onClick={(e) => onFeedbackOkClick(e)}
                >
                    <FeedbackOk active={feedbackOkResult}/>
                    <span 
                        className='feedback-tip' 
                        style={{color: feedbackOkResult? config?.theme?.ThemePrimaryColor ?? '#5161FF': '#666666'}}
                    >
                        满意
                    </span>
                </div>
                <div 
                    className={(feedbackOkResult || feedbackErrorResult) ? 'aichat-content-feedback-error-disabled' : 'aichat-content-feedback-error'}
                    onClick={(e) => onFeedbackErrorClick(e)}
                >
                    <FeedbackError active={feedbackErrorResult}/>
                    <span 
                        className='feedback-tip'
                        style={{color: feedbackErrorResult? config?.theme?.ThemePrimaryColor ?? '#5161FF' : '#666666'}}
                    >
                        { notFeedbackDropDown? '不满意' : '反馈' }
                    </span>
                </div>
            </div>
            {
                feedbackDropDown && (
                    <div className='aichat-content-feedbackDropDown'>
                        <div className='feedbackDropDown-header'>
                            <div className='header-logo'></div>
                            <div className='header-content' style={{color: config?.theme?.ThemePrimaryColor ?? '#696DFF'}}>意见反馈</div>
                        </div>
                        <div className='feedbackDropDown-body'>
                            {
                                tagList.map((item) => {
                                    return (
                                        <div 
                                            className='body-list'
                                            style={ selectTag === item.key ? 
                                                { background: config?.theme?.ThemePrimaryColor ?? '#5161FF', color: '#ffffff'} : 
                                                { background: config?.theme?.ThemeSecondaryColor ?? '#E5EFFE', color: '#000000'}
                                            }
                                            key={item.key}
                                            onClick = {() => {
                                                const result = getSelectTag(tagList, item.key);
                                                if(selectTag === result.key) {
                                                    setSelectTag(-1);
                                                    return;
                                                }
                                                setSelectTag(result.key);
                                            }}
                                        >
                                            {item.content}
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className='feedbackDropDown-footer'>
                            <div className='footer-tip'>其他意见（请在下方输入）</div>
                            <TextArea
                                placeholder="请输入意见"
                                style={{ height: 90, resize: 'none' }}
                                className='footer-input'
                                bordered
                                value={inputValue}
                                onChange={(e) => setInputValue(e.target.value) }
                            />
                            <Button
                                type='primary'
                                className='footer-button'
                                onClick={ async (e) => {
                                    if(buttonState) return;
                                    e.stopPropagation();
                                    setFeedbackDropDown(false);
                                    const resultTag = tagList.filter(item => item.key === selectTag)[0];
                                    const feedback_tag = resultTag ? resultTag.content : '';
                                    const result = await annotations(messageId, feedback_tag, inputValue);
                                    if(result === 'success')  setFeedbackErrorResult(true);
                                }}
                            >
                                提交
                            </Button>
                        </div>
                    </div>
                )
            } 
            {
                (feedbackOkResult || feedbackErrorResult) && (
                    <div className='aichat-content-feedbackResult'>
                        <FeedbackSuccess/>
                        <span>反馈成功</span>
                    </div>
                )
            }
        </div>
    )
}

async function annotations(messageId: string, feedback_tag: string = '', suggest_text: string = '' ) {
    const config = getConfig();
    const env = getEnv();

    let userId: string | number = '';

    if(config?.userId){
        userId = config.userId;
    }
    
    const result = await appInstance.client.post(`https://${env}ai-api.chanapp.chanjet.com/v1/messages/${messageId}/annotations`, {
        content: `${feedback_tag}/n${suggest_text}`,
        user: userId,
    });
    return result.data.result;
}

// 设置反馈内容列表
function annotationsContent(): Array<{key: number, content: string}>{
    const currentPlugin = getCurrentPlugin();
    const contentList = currentPlugin?.customFeedbackMsg ? currentPlugin.customFeedbackMsg : [];
    return contentList;
}

// 获取意见反馈选中的tag
function getSelectTag(tagList: Array<{key: number, content: string}>, selectKey: number){
    const selectTag = tagList.find((item) => item.key === selectKey);
    return selectTag;
}